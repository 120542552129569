import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { StyledLink } from '../../../utils/ui';
import { getOriginalImageSrc } from '../../../utils/formatter';

const BlogsWrapper = styled.div`
  max-width: 1060px;
  padding-top: 80px;
  padding-bottom: 80px;
  .read_more a {
    position: relative;
    font-size: 16px;
    margin-top: 22px;
    color: #fff !important;
  }
  .read_more a::before {
    content: '';
    position: absolute;
    width: 100%;
    height: 2px;
    bottom: 0;
    left: 0;
    background-color: #fff;
    visibility: hidden;
    transition: all 0.3s ease-in-out 0s;
    transform: scaleX(0);
  }
  .read_more a:hover::before {
    visibility: visible;
    transform: scaleX(1);
  }
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  row-gap: 26px;
  column-gap: 26px;

  .blog-item-highlight {
    width: 100%;
    height: 370px;
    position: relative;
    display: flex;
    grid-column-start: 1;
    grid-column-end: 4;
    img {
      object-fit: cover;
      width: 100%;
      height: 100%;
    }
    .blog-item-content {
      z-index: 2;
      position: absolute;
      top: 0;
      bottom: 0;
      right: 0;
      left: 600px;
      background-color: rgba(0, 0, 0, 0.4);
      padding: 32px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }
    .badge {
      color: #fff;
      font-size: 14px;
      margin-top: 24px;
    }
    .title {
      color: #fff;
      font-family: Cormorant Infant;
      font-style: normal;
      font-weight: bold;
      font-size: 36px;
      line-height: 48px;
      margin-block-start: 0.5em;
      margin-block-end: 0.5em;
    }
  }

  .blog-item {
    img {
      object-fit: cover;
      width: 100%;
      height: 250px;
    }
    .title {
      font-size: 20px;
      font-weight: bold;
    }
  }
`;

const BlogItemHighlight = ({ blogPost }) => {
  const { slug, blog_thumbnail, title, description } = blogPost;
  return (
    <StyledLink className="blog-item-highlight" to={`/blog/${slug}`}>
      <img src={getOriginalImageSrc(blog_thumbnail)} alt={title} />
      <div className="blog-item-content">
        <div>
          <p className="badge">MỚI NHẤT</p>
          <p className="title">{title}</p>
        </div>
        <p className="read_more">
          <StyledLink href="#">Đọc tiếp</StyledLink>
        </p>
      </div>
    </StyledLink>
  );
};

const BlogItem = ({ blogPost }) => {
  const { slug, blog_thumbnail, title, description } = blogPost;
  return (
    <StyledLink className="blog-item" to={`/blog/${slug}`}>
      <img src={getOriginalImageSrc(blog_thumbnail)} alt={title} />
      <p className="title">{title}</p>
      <p className="description">{description}</p>
    </StyledLink>
  );
};

const Blogs = ({ blogData }) => {
  return (
    <BlogsWrapper>
      {blogData.map((blogPost, index) =>
        index === 0 ? (
          <BlogItemHighlight key={blogPost.id} blogPost={blogPost} />
        ) : (
          <BlogItem key={blogPost.id} blogPost={blogPost} />
        ),
      )}
    </BlogsWrapper>
  );
};

Blogs.propTypes = {
  blogData: PropTypes.array,
};

export default Blogs;
