import React from 'react';
import styled from 'styled-components';
import PageBlog from '../queries/page-blog';
import SiteLayout from '../components/SiteLayout';
import { BannerImageText } from '../components/common/';
import Blogs from '../components/page/BlogPage/Blogs';
import { flattenStrapiData } from '../utils/formatter';
import { useScrolldown } from '../hooks/useScrolldown';

const ProductWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export default function Blog() {
  useScrolldown('#banner_wrapper');
  const renderContent = ({ strapiPageBlog, allStrapiBlog: blogData }) => {
    const { banner_text } = strapiPageBlog;
    return (
      <ProductWrapper>
        <Blogs blogData={flattenStrapiData(blogData)} />
      </ProductWrapper>
    );
  };
  const LAYOUT_KEY = 'BLOG';
  return (
    <SiteLayout activeKey={LAYOUT_KEY}>
      <PageBlog renderContent={renderContent} />
    </SiteLayout>
  );
}
